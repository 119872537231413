<template>
  <section class="lightcar-section">
    <div class="container">
      <div class="lightcar-section__inner">
        <div class="lightcar-section__image">
          <img
            src="@/assets/images/heavycar.png"
            style="width: 100%; height: 100%; object-fit: cover;"
            class="about-img heavycar-image"
            alt="heavycar-image"
          >
        </div>
        <div class="lightcar-section__info">
          <div class="lightcar-section__title main__title">
            ТО и обслуживание грузового транспорта
          </div>
          <div class="lightcar-section__text main__text">
            Испытательный центр «ТЭЦ» предлагает водителям грузовых автомобилей пройти техосмотр
            транспортного средства, чтобы получить Международный сертификат техосмотра. Программа
            «Технического осмотра грузового автотранспорта» разработана с учетом всех рекомендаций
            по регулярному техническому обслуживанию колесного транспорта.
          </div>
          <p class="medium__text price-description">
            от 4000 грн
          </p>
          <button
            class="btn btn--black"
            @click="openModal()"
          >
            Заказать полную оценку
          </button>
        </div>
      </div>
    </div>
    <pop-up />
  </section>
</template>

<script>
export default {
    name: 'HeavyCarDescr',
    components: {
    PopUp: () => import('@/components/PopUp')
    },
    methods: {
    openModal() {
        document.querySelector('.popUp').classList.add('popUp__active')
        document.querySelector('body').classList.add('lock')
    },
    closeModal() {
        document.querySelector('.popUp').classList.remove('popUp__active')
    }
    }
}
</script>
